<template>
  <div>
    <div
      class="text-detail"
      v-show="EpicentralDetail && Object.keys(EpicentralDetail).length"
    >
      <TextMaterialsSlider
        v-if="banners && banners.length"
        :banners="banners"
        :title="EpicentralDetail.name"
        :color="'#fff'"
      />
      <div class="content" v-else>
        <div class="text-detail__title" v-html="EpicentralDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Epicentral', link: { name: 'Epicentral' } },
          {
            name: EpicentralDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="text-detail__row mt-4">
          <div class="text-detail__body">
            <div class="material__tags-wrap" v-if="false">
              <div class="material__tags">
                <div
                  class="material__tag"
                  v-if="EpicentralDetail.material_type"
                >
                  {{ EpicentralDetail.material_type }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in EpicentralDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in EpicentralDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="EpicentralDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
              <Favorite
                class="large"
                v-if="!$route.query.access && false"
                :watchLater="EpicentralDetail.watch_later"
                :favorite="EpicentralDetail.favorite"
                @watchLater="
                  watchLater(
                    EpicentralDetail.watch_later ? 'DELETE' : 'POST',
                    EpicentralDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    EpicentralDetail.favorite ? 'DELETE' : 'POST',
                    EpicentralDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>
            <div
              class="failure-page__description"
              v-if="EpicentralDetail.material_type !== 'Видео'"
              v-html="EpicentralDetail.description"
            ></div>
            <div
              v-if="
                EpicentralDetail &&
                Object.keys(EpicentralDetail).length &&
                EpicentralDetail.material_type !== 'Видео'
              "
            >
              <InfoDrop
                v-for="(info, ind) in filteredContent"
                :key="ind"
                :color="EpicentralDetail.color"
                :item="info"
              />
            </div>
            <div
              class="epicentral-detail__video"
              v-show="EpicentralDetail.video"
            >
              <video
                controls
                :src="EpicentralDetail.video"
                :poster="
                  EpicentralDetail.video_preview
                    ? EpicentralDetail.video_preview
                    : null
                "
                ref="videoElement"
              ></video>

              <div
                v-show="EpicentralDetail.description"
                class="epicentral-detail__subtitle"
                v-html="EpicentralDetail.description"
                ref="description"
              ></div>
            </div>
          </div>

          <div class="sticky">
            <RightAside
              v-if="asideItems && asideItems.length"
              :items="asideItems"
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
            />
          </div>
        </div>
        <div class="text-detail__buttons">
          <router-link
            :to="{ name: 'Epicentral' }"
            class="text-detail__load button button_empty-pink"
          >
            <span class="mr-2 ">
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.5L1.5 9L9 16.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
            Назад
          </router-link>
          <div
            class="text-detail__up button button_empty-pink"
            @click="toTop()"
          >
            К началу страницы
            <span class="ml-2">
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="
          EpicentralDetail.nosology &&
          EpicentralDetail.nosology.length &&
          sliderItems.length &&
          false
        "
        @clickMaterials="clickMaterials"
        @toNosol="toNosol"
        :nosology="EpicentralDetail.nosology[0]"
        :items="sliderItems"
        :page="'EpicentralDetail'"
      />
    </div>
    <div v-if="EpicentralDetail === 404"><Choch class="inPage" /></div>
    <div v-if="EpicentralDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import "@/assets/scss/detail_content.scss";
import "@/assets/scss/clinical_case.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Favorite from "../../components/pageComponents/Favorite.vue";
import TextMaterialsSlider from "@/components/main/TextMaterialsSlider.vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";

export default {
  metaInfo() {
    if (this.EpicentralDetail.name) {
      return { title: this.EpicentralDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.EpicentralDetail.name };
    }
  },
  name: "EpicentralDetail",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    Favorite,
    TextMaterialsSlider,
    InfoDrop,
  },
  data: () => ({
    items: [],
    index: null,
  }),
  computed: {
    ...mapGetters(["EpicentralDetail", "TextList"]),
    filteredContent() {
      return this.EpicentralDetail.materials.filter((el) => el.active);
    },
    sliderItems() {
      return this.TextList
        ? this.TextList.filter((el) => el.slug !== this.EpicentralDetail.slug)
        : [];
    },
    filter() {
      if (this.EpicentralDetail && Object.keys(this.EpicentralDetail).length) {
        return {
          therapeutic_areas: [this.EpicentralDetail.nosology[0]?.slug],
        };
      } else {
        return {};
      }
    },
    banners() {
      let arr = [];
      if (
        this.EpicentralDetail.banner_desktop ||
        this.EpicentralDetail.banner_tablet ||
        this.EpicentralDetail.banner_mobile
      ) {
        arr.push({
          image_desktop: this.EpicentralDetail.banner_desktop,
          image_tablet: this.EpicentralDetail.banner_tablet,
          image_mobile: this.EpicentralDetail.banner_mobile,
        });
      }
      return arr;
    },
    asideItems() {
      if (this.EpicentralDetail && Object.keys(this.EpicentralDetail).length) {
        return [
          {
            title: "Препараты АЗ",
            // descr: this.EpicentralDetail.nosology.map((el) => el.title).join(","),
            image: require("@/assets/img/cardio-img/aside-1.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.EpicentralDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Календарь мероприятий",
            image: require("@/assets/img/cardio-img/aside-2.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
            event: "calendarClick",
            to: {
              name: "Events",
              query: {
                therapeutic_areas: this.EpicentralDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Терапевтические <br>области",
            image: require("@/assets/img/cardio-img/aside-4.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-4_small.jpg"),
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchEpicentralDetail",
      "fetchTextMaterials",
      "fetchTextWatchLater",
      "fetchTextFavorite",
    ]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterial page medications click", {
        //   "textmaterial page medications click": {
        //     ...this.$root.ymFields,
        //   },
        // });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterial  page events click", {
        //   "textmaterial  page events click": {
        //     ...this.$root.ymFields,
        //   },
        // });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterial page therapeutic areas click", {
        //   "textmaterial page therapeutic areas click": {
        //     ...this.$root.ymFields,
        //   },
        // });
      }
      this.$router.push(item.to).catch(() => {});
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterial page more materials click", {
        //   "textmaterial page more materials click": {
        //     "textmaterial page name": name,
        //     ...this.$root.ymFields,
        //   },
        // });
      }
    },
    async watchLater(method, slug, filter, favoritePage) {
      await this.fetchTextWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchEpicentralDetail({ slug: this.$route.params.slug, access: this.$route.query.access || null, });
    },
    async favorite(method, slug, filter, favoritePage) {
      await this.fetchTextFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchEpicentralDetail({ slug: this.$route.params.slug, access: this.$route.query.access || null, });
    },
    toNosol(title, to) {
      const vm = this;

      // ym(
      //   91468266,
      //   "reachGoal",
      //   "textmaterial page current therapeutic areas click",
      //   {
      //     "textmaterial page current therapeutic areas click": {
      //       "therapeutic area": title,
      //       ...this.$root.ymFields,
      //     },
      //   }
      // );
      this.$router.push(to).catch(() => {});
    },
  },
  async mounted() {
    const vm = this;
    if (
      this.EpicentralDetail.file &&
      Object.keys(this.EpicentralDetail.file).length
    ) {
      window.open(`${this.EpicentralDetail.file.url}`, "_self");
    }
    
  },
  watch: {
    "$route.params.slug": {
      handler: async function () {
        await this.fetchEpicentralDetail({ slug: this.$route.params.slug, access: this.$route.query.access || null, });
      },
      deep: true,
      immediate: true,
    },
    async EpicentralDetail() {
      // if (
      //   // !this.TextList.length &&
      //   this.EpicentralDetail &&
      //   Object.keys(this.EpicentralDetail).length
      // ) {
      //   await this.fetchTextMaterials(this.filter);
      // }
      // if (this.EpicentralDetail.file && Object.keys(this.EpicentralDetail.file).length) {
      //   window.open(`${this.EpicentralDetail.file.url}`, "_self");
      // }
      // localStorage.setItem("textBlock", this.EpicentralDetail.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.epicentral-detail {
  &__subtitle {
    margin-top: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__video {
    position: relative;
    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 29%) rotate(64.337deg);
      display: block;
      content: "";
      border-radius: 462.487px;
      background: rgba(155, 55, 255, 0.2);
      filter: blur(77px);
      width: 397.897px;
      height: 462.487px;

      @media screen and (max-width: 1220px) {
        width: 100px;
        height: 200px;
        transform: rotate(64deg) translate(-50%, 0);
        bottom: -125px;
      }
    }

    & video {
      position: relative;
      z-index: 2;
    }
  }
}
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__body {
    max-width: 800px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}

.failure-page {
  &__title {
    margin-bottom: 40px;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    line-height: 29px;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 29px;
    }
  }

  &__description {
    color: #656969;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }
}
</style>
